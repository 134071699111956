/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetLeadExportTasks200Response } from '../models';
// @ts-ignore
import { GetScheduledLeadExportSetting200Response } from '../models';
// @ts-ignore
import { ImmediateLeadExportSetting } from '../models';
// @ts-ignore
import { ScheduledLeadExportSetting } from '../models';
/**
 * LeadExportApi - axios parameter creator
 * @export
 */
export const LeadExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リード定期エクスポート設定削除API
         * @summary リード定期エクスポート設定削除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledLeadExportSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_export/scheduled/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リードエクスポートタスク取得API
         * @summary リードエクスポートタスク取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadExportTasks: async (page?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_export/tasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リード定期エクスポート設定取得API
         * @summary リード定期エクスポート設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledLeadExportSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_export/scheduled/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リードエクスポート即時実行API
         * @summary リードエクスポート即時実行API
         * @param {ImmediateLeadExportSetting} [immediateLeadExportSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImmediateLeadExport: async (immediateLeadExportSetting?: ImmediateLeadExportSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_export/immediate/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(immediateLeadExportSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リード定期エクスポート設定更新API
         * @summary リード定期エクスポート設定更新API
         * @param {ScheduledLeadExportSetting} [scheduledLeadExportSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScheduledLeadExportSetting: async (scheduledLeadExportSetting?: ScheduledLeadExportSetting, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_export/scheduled/setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledLeadExportSetting, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadExportApi - functional programming interface
 * @export
 */
export const LeadExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadExportApiAxiosParamCreator(configuration)
    return {
        /**
         * リード定期エクスポート設定削除API
         * @summary リード定期エクスポート設定削除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteScheduledLeadExportSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteScheduledLeadExportSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リードエクスポートタスク取得API
         * @summary リードエクスポートタスク取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeadExportTasks(page?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLeadExportTasks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeadExportTasks(page, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リード定期エクスポート設定取得API
         * @summary リード定期エクスポート設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduledLeadExportSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetScheduledLeadExportSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduledLeadExportSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リードエクスポート即時実行API
         * @summary リードエクスポート即時実行API
         * @param {ImmediateLeadExportSetting} [immediateLeadExportSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postImmediateLeadExport(immediateLeadExportSetting?: ImmediateLeadExportSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postImmediateLeadExport(immediateLeadExportSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リード定期エクスポート設定更新API
         * @summary リード定期エクスポート設定更新API
         * @param {ScheduledLeadExportSetting} [scheduledLeadExportSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putScheduledLeadExportSetting(scheduledLeadExportSetting?: ScheduledLeadExportSetting, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putScheduledLeadExportSetting(scheduledLeadExportSetting, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadExportApi - factory interface
 * @export
 */
export const LeadExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadExportApiFp(configuration)
    return {
        /**
         * リード定期エクスポート設定削除API
         * @summary リード定期エクスポート設定削除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteScheduledLeadExportSetting(options?: any): AxiosPromise<void> {
            return localVarFp.deleteScheduledLeadExportSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * リードエクスポートタスク取得API
         * @summary リードエクスポートタスク取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeadExportTasks(page?: number, options?: any): AxiosPromise<GetLeadExportTasks200Response> {
            return localVarFp.getLeadExportTasks(page, options).then((request) => request(axios, basePath));
        },
        /**
         * リード定期エクスポート設定取得API
         * @summary リード定期エクスポート設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduledLeadExportSetting(options?: any): AxiosPromise<GetScheduledLeadExportSetting200Response> {
            return localVarFp.getScheduledLeadExportSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * リードエクスポート即時実行API
         * @summary リードエクスポート即時実行API
         * @param {ImmediateLeadExportSetting} [immediateLeadExportSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postImmediateLeadExport(immediateLeadExportSetting?: ImmediateLeadExportSetting, options?: any): AxiosPromise<void> {
            return localVarFp.postImmediateLeadExport(immediateLeadExportSetting, options).then((request) => request(axios, basePath));
        },
        /**
         * リード定期エクスポート設定更新API
         * @summary リード定期エクスポート設定更新API
         * @param {ScheduledLeadExportSetting} [scheduledLeadExportSetting] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putScheduledLeadExportSetting(scheduledLeadExportSetting?: ScheduledLeadExportSetting, options?: any): AxiosPromise<boolean> {
            return localVarFp.putScheduledLeadExportSetting(scheduledLeadExportSetting, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadExportApi - interface
 * @export
 * @interface LeadExportApi
 */
export interface LeadExportApiInterface {
    /**
     * リード定期エクスポート設定削除API
     * @summary リード定期エクスポート設定削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApiInterface
     */
    deleteScheduledLeadExportSetting(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * リードエクスポートタスク取得API
     * @summary リードエクスポートタスク取得API
     * @param {number} [page] infinityLoading用ページ番号
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApiInterface
     */
    getLeadExportTasks(page?: number, options?: AxiosRequestConfig): AxiosPromise<GetLeadExportTasks200Response>;

    /**
     * リード定期エクスポート設定取得API
     * @summary リード定期エクスポート設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApiInterface
     */
    getScheduledLeadExportSetting(options?: AxiosRequestConfig): AxiosPromise<GetScheduledLeadExportSetting200Response>;

    /**
     * リードエクスポート即時実行API
     * @summary リードエクスポート即時実行API
     * @param {ImmediateLeadExportSetting} [immediateLeadExportSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApiInterface
     */
    postImmediateLeadExport(immediateLeadExportSetting?: ImmediateLeadExportSetting, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * リード定期エクスポート設定更新API
     * @summary リード定期エクスポート設定更新API
     * @param {ScheduledLeadExportSetting} [scheduledLeadExportSetting] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApiInterface
     */
    putScheduledLeadExportSetting(scheduledLeadExportSetting?: ScheduledLeadExportSetting, options?: AxiosRequestConfig): AxiosPromise<boolean>;

}

/**
 * Request parameters for getLeadExportTasks operation in LeadExportApi.
 * @export
 * @interface LeadExportApiGetLeadExportTasksRequest
 */
export interface LeadExportApiGetLeadExportTasksRequest {
    /**
     * infinityLoading用ページ番号
     * @type {number}
     * @memberof LeadExportApiGetLeadExportTasks
     */
    readonly page?: number
}

/**
 * Request parameters for postImmediateLeadExport operation in LeadExportApi.
 * @export
 * @interface LeadExportApiPostImmediateLeadExportRequest
 */
export interface LeadExportApiPostImmediateLeadExportRequest {
    /**
     * 
     * @type {ImmediateLeadExportSetting}
     * @memberof LeadExportApiPostImmediateLeadExport
     */
    readonly immediateLeadExportSetting?: ImmediateLeadExportSetting
}

/**
 * Request parameters for putScheduledLeadExportSetting operation in LeadExportApi.
 * @export
 * @interface LeadExportApiPutScheduledLeadExportSettingRequest
 */
export interface LeadExportApiPutScheduledLeadExportSettingRequest {
    /**
     * 
     * @type {ScheduledLeadExportSetting}
     * @memberof LeadExportApiPutScheduledLeadExportSetting
     */
    readonly scheduledLeadExportSetting?: ScheduledLeadExportSetting
}

/**
 * LeadExportApi - object-oriented interface
 * @export
 * @class LeadExportApi
 * @extends {BaseAPI}
 */
export class LeadExportApi extends BaseAPI implements LeadExportApiInterface {
    /**
     * リード定期エクスポート設定削除API
     * @summary リード定期エクスポート設定削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApi
     */
    public deleteScheduledLeadExportSetting(options?: AxiosRequestConfig) {
        return LeadExportApiFp(this.configuration).deleteScheduledLeadExportSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リードエクスポートタスク取得API
     * @summary リードエクスポートタスク取得API
     * @param {LeadExportApiGetLeadExportTasksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApi
     */
    public getLeadExportTasks(requestParameters: LeadExportApiGetLeadExportTasksRequest = {}, options?: AxiosRequestConfig) {
        return LeadExportApiFp(this.configuration).getLeadExportTasks(requestParameters.page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リード定期エクスポート設定取得API
     * @summary リード定期エクスポート設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApi
     */
    public getScheduledLeadExportSetting(options?: AxiosRequestConfig) {
        return LeadExportApiFp(this.configuration).getScheduledLeadExportSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リードエクスポート即時実行API
     * @summary リードエクスポート即時実行API
     * @param {LeadExportApiPostImmediateLeadExportRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApi
     */
    public postImmediateLeadExport(requestParameters: LeadExportApiPostImmediateLeadExportRequest = {}, options?: AxiosRequestConfig) {
        return LeadExportApiFp(this.configuration).postImmediateLeadExport(requestParameters.immediateLeadExportSetting, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リード定期エクスポート設定更新API
     * @summary リード定期エクスポート設定更新API
     * @param {LeadExportApiPutScheduledLeadExportSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadExportApi
     */
    public putScheduledLeadExportSetting(requestParameters: LeadExportApiPutScheduledLeadExportSettingRequest = {}, options?: AxiosRequestConfig) {
        return LeadExportApiFp(this.configuration).putScheduledLeadExportSetting(requestParameters.scheduledLeadExportSetting, options).then((request) => request(this.axios, this.basePath));
    }
}
