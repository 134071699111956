<template>
  <div
    v-loading="loading"
    class="scheduled-export-setting"
  >
    <ScheduledExportSettingShow
      v-if="setting != null"
      :model="setting"
      @after-delete="handleAfterDelete"
    />
    <ScheduledExportSettingForm
      v-else
      :number-of-target-leads="numberOfTargetLeads"
      :number-of-leads="numberOfLeads"
      :lead-list-filter-params="leadListFilterParams"
      @after-save="handleAfterSave"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { ScheduledLeadExportSetting } from '@/api/openapi';
import { LeadExportApiService } from '@/api/user/resources/lead_export';
import { useWait } from '@/composable/vue-wait';
import ScheduledExportSettingForm from './ScheduledExportSettingForm.vue';
import ScheduledExportSettingShow from './ScheduledExportSettingShow.vue';
import type { TLeadListProps } from './types';

type TProps = TLeadListProps;

defineProps<TProps>();

const { doActionWithWait, wait } = useWait();
const api = new LeadExportApiService();

const setting = ref<ScheduledLeadExportSetting>();
const loading = computed(() => {
  return wait.is(['fetchSettingWait', 'putScheduledLeadExportSettingWait', 'deleteScheduledLeadExportSettingWait']);
});

onMounted(async () => {
  await fetchSetting();
});
const fetchSetting = async () => {
  await doActionWithWait('fetchSettingWait', async () => {
    const { data } = await api.getScheduledLeadExportSetting();
    setting.value = data.scheduledLeadExportSetting;
  });
};
const handleAfterDelete = () => {
  setting.value = undefined;
};
const handleAfterSave = async () => {
  console.log('handleAfterSave');
  await fetchSetting();
};
</script>
