import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

dayjs.locale('ja');
dayjs.updateLocale('ja', {
  weekStart: 1,
});
dayjs.tz.setDefault('Asia/Tokyo');

type TFormatValue = dayjs.ConfigType | null | undefined;

const FORMAT_DATE = 'YYYY/MM/DD';
const FORMAT_DATE_WITHOUT_YEAR = 'MM/DD';
const FORMAT_DATE_TIME = 'YYYY/MM/DD HH:mm:ss';
const FORMAT_SHORT_TIME = 'H:mm';

/**
 * 指定した値を設定したDateを作成して返す
 * 値を指定しない場合は単に新しいDateを作成して返す
 */
const newDate = (value?: string): Date => {
  return dayjs(value).toDate();
};

/**
 * 指定した時間を設定したDateを作成して返す
 */
const newDateByTime = (hour: number, minutes: number, seconds: number, date?: dayjs.ConfigType): Date => {
  return dayjs(date).set('hour', hour).set('minute', minutes).set('second', seconds).toDate();
};

const getDiff = (to: dayjs.ConfigType, from?: dayjs.ConfigType, unit?: dayjs.QUnitType | dayjs.OpUnitType): number => {
  return dayjs(from).diff(to, unit);
};

const getDateUnit = (date: dayjs.ConfigType, unit: dayjs.UnitType): number | undefined => {
  if (!date) return undefined;
  return dayjs(date).get(unit);
};

const isDateAfter = (date: dayjs.ConfigType, compareDate: dayjs.ConfigType): boolean => {
  return dayjs(date).isAfter(compareDate);
};

/**
 * ※dayjsの日付としての妥当性は判断不可能なので注意（dayjsのAPIが対応していないため）
 */
const isDateValid = (date: dayjs.ConfigType) => {
  // NOTE: stringは直接dayjsに渡すとinvalidな値でもvalidな値にparseされるため、Date型に変換してから判定する
  const parsedDate = typeof date === 'string' ? new Date(date) : date;

  return dayjs(parsedDate).isValid();
};

const isISO8601String = (dateString: unknown) => {
  return typeof dateString === 'string' && dayjs(dateString, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', true).isValid();
};

const convertToDate = (value: dayjs.ConfigType): Date => {
  return value instanceof Date ? value
    : dayjs.isDayjs(value) ? value.toDate()
      : dayjs(value).toDate();
};

const mergeDateAndTime = (date: dayjs.ConfigType, time: dayjs.ConfigType): Date | null => {
  if (!isDateValid(date) || !isDateValid(time)) return null;
  const year = dayjs(date).get('year');
  const month = dayjs(date).get('month');
  const day = dayjs(date).get('date');
  const hour = dayjs(time).get('hour');
  const min = dayjs(time).get('minute');
  const sec = dayjs(time).get('second');
  return new Date(year, month, day, hour, min, sec);
};

const addMinutesToCurrentDateTime = (minutes: number): string => {
  return format(add(dayjs(), minutes, 'minute'), FORMAT_DATE_TIME);
};

const add = (date: dayjs.ConfigType, value: number, unit: dayjs.ManipulateType): Date => {
  return dayjs(date).add(value, unit).toDate();
};

const startOf = (date: dayjs.ConfigType, unit: dayjs.OpUnitType): Date => {
  return dayjs(date).startOf(unit).toDate();
};

const endOf = (date: dayjs.ConfigType, unit: dayjs.OpUnitType): Date => {
  return dayjs(date).endOf(unit).toDate();
};

const formatDateTime = (value: TFormatValue): string => {
  return format(value, FORMAT_DATE_TIME);
};

const formatShorterDateTime = (value: TFormatValue): string => {
  if (!value) return '';

  const convertedDateAndTime = dayjs(value).format(`${FORMAT_DATE} ${FORMAT_SHORT_TIME}`);
  if (dayjs().isSame(convertedDateAndTime, 'year')) {
    // 年が同じ場合は表示しない
    return dayjs(convertedDateAndTime).format(`${FORMAT_DATE_WITHOUT_YEAR} ${FORMAT_SHORT_TIME}`);
  } else {
    return convertedDateAndTime;
  }
};

const formatShorterDate = (value: TFormatValue): string => {
  if (!value) return '';

  const convertedDate = dayjs(value).format(FORMAT_DATE);
  if (dayjs().isSame(convertedDate, 'year')) {
    // 年が同じ場合は表示しない
    return dayjs(convertedDate).format(FORMAT_DATE_WITHOUT_YEAR);
  } else {
    return convertedDate;
  }
};

const formatShorterTimeOrDateTime = (value: TFormatValue, diffDate?: TFormatValue): string => {
  // 日付が同じ場合は時刻のみ表示
  if (dayjs(diffDate).isSame(value, 'day')) {
    return formatShortTime(value);
  } else {
    return formatShorterDateTime(value);
  }
};

const formatShortTime = (value: TFormatValue): string => {
  return format(value, FORMAT_SHORT_TIME);
};

const formatDate = (value: TFormatValue): string => {
  return format(value, FORMAT_DATE);
};

const formatUtcISO8601String = (value: TFormatValue): string => {
  if (!value) return '';
  return dayjs(value).utc().toISOString();
};

/**
 * ！！基本的には定義済みのmethodを使用すること！！
 * どうしても任意のフォーマットが必要な時のみこの関数を利用する
 * 例） - ロジックのみで使う場合：これを使ってもいい
 *     - 表示のフォーマット：定義済みのものを使う or 新しい定義を作成する
 */
const format = (value: TFormatValue, format?: string): string => {
  if (!value) return '';
  return dayjs(value).format(format);
};

type DateRangeOperator = 'last' | 'next';

const getDateRange = (anchor: string, count: number, unit: dayjs.ManipulateType, operator: DateRangeOperator): string[] => {
  let range: string[];
  if (unit?.toString().toLowerCase() !== 'days') count -= 1;
  if (!anchor) {
    range = [];
  } else if (operator === 'last') {
    const toDate = dayjs(anchor).endOf(unit).format(FORMAT_DATE);
    const fromDate= dayjs(toDate).subtract(count, unit).startOf(unit).format(FORMAT_DATE);
    range = [fromDate, toDate];
  } else if (operator === 'next') {
    const fromDate = dayjs(anchor).startOf(unit).format(FORMAT_DATE);
    const toDate = dayjs(fromDate).add(count, unit).endOf(unit).format(FORMAT_DATE);
    range = [fromDate, toDate];
  }
  return range;
};

type TConfigType = dayjs.ConfigType;
export type {
  TConfigType,
  DateRangeOperator,
};
export {
  newDate,
  newDateByTime,
  getDiff,
  getDateUnit,
  isDateAfter,
  isDateValid,
  isISO8601String,
  convertToDate,
  mergeDateAndTime,
  addMinutesToCurrentDateTime,
  add,
  startOf,
  endOf,
  formatDateTime,
  formatShorterDateTime,
  formatShorterDate,
  formatShorterTimeOrDateTime,
  formatShortTime,
  formatDate,
  formatUtcISO8601String,
  format,
  getDateRange,
};
