<template>
  <CommonTask
    :content="nextAction.content"
    :owner="nextAction.owner"
    :title="nextAction.name"
    :date-time="nextAction.reservedAt"
    :is-sequence="isSequenceAction"
    content-expandable
  >
    <template #status>
      <BPriorityPreview
        :priority="nextAction.priority"
        size="micro"
      />
    </template>
    <template #optionalActions>
      <LeadLink
        v-if="nextAction.callTarget != null"
        in-new-tab
        :call-target-id="nextAction.callTarget.id"
        class="lead-link"
      >
        <div class="company-name">
          {{ nextAction.callTarget.companyName || $t('general.none') }}
        </div>
        <div class="full-name">
          {{ nextAction.callTarget.fullName || $t('general.none') }}
        </div>
      </LeadLink>
      <span v-else>
        {{ $t('general.none') }}
      </span>
    </template>
  </CommonTask>
</template>

<script lang="ts" setup>
import { NextAction } from '@/api/openapi';
import LeadLink from '@/components/organisms/user/general/LeadLink.vue';
import { useNextActionComputed } from '@/composable/user/nextAction/next-action';
import CommonTask from '../lead/task/CommonTask.vue';

type TProps = {
  nextAction: NextAction;
};

const props = defineProps<TProps>();
const { isSequenceAction } = useNextActionComputed(props.nextAction);
</script>

<style lang="scss" scoped>
.task-container {
  :deep(.task-user-area, .task-info-area, .task-content-area) {
    @include m-flex-align-center;
  }
  :deep(.task-info-area) {
    @include m-fixed-width(450px);

    .task-info-main {
      @include m-fixed-width(200px);
    }
    .task-optional-actions {
      display: block;
      margin-left: $basespace-400;
      max-width: 210px;

      .company-name {
        padding-bottom: $basespace-50;
      }
      .company-name, .full-name {
        @include m-truncate-text;
      }
    }
  }
}
</style>
