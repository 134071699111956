import ApiService, { ApiArguments } from '@/api/api_service';
import { GetLeadExportTasks200Response, GetScheduledLeadExportSetting200Response, LeadExportApi, LeadExportApiGetLeadExportTasksRequest, LeadExportApiPostImmediateLeadExportRequest, LeadExportApiPutScheduledLeadExportSettingRequest } from '@/api/openapi';

export class LeadExportApiService extends ApiService {
  api = new LeadExportApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getLeadExportHistories(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<LeadExportApiGetLeadExportTasksRequest>,
  ) {
    return this.checkResponse<GetLeadExportTasks200Response>(
      this.api.getLeadExportTasks(request, options),
      errorHandlers,
    );
  }

  async postImmediateLeadExport(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<LeadExportApiPostImmediateLeadExportRequest>,
  ) {
    return this.checkResponse<void>(
      this.api.postImmediateLeadExport(request, options),
      errorHandlers,
    );
  }

  async getScheduledLeadExportSetting(
    {
      options,
      errorHandlers,
    }: ApiArguments<never> = {},
  ) {
    return this.checkResponse<GetScheduledLeadExportSetting200Response>(
      this.api.getScheduledLeadExportSetting(options),
      errorHandlers,
    );
  }

  async putScheduledLeadExportSetting(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<LeadExportApiPutScheduledLeadExportSettingRequest>,
  ) {
    return this.checkResponse<void>(
      this.api.putScheduledLeadExportSetting(request, options),
      errorHandlers,
    );
  }

  async deleteScheduledLeadExportSetting(
    {
      options,
      errorHandlers,
    }: ApiArguments<never> = {},
  ) {
    return this.checkResponse<void>(
      this.api.deleteScheduledLeadExportSetting(options),
      errorHandlers,
    );
  }
}
