<template>
  <h3 class="lead-view-list-title">
    <BIcon
      rounded
    >
      groups
    </BIcon>
    {{ $t('home.leadViewList.title') }}
  </h3>
  <div
    v-loading="loading"
    class="lead-view-list"
  >
    <div class="scroll-container">
      <div class="list-container">
        <template v-if="!loading">
          <template v-if="leadViews.length === 0">
            <BEmptyBox
              class="empty-lead-view-list"
              display-only
            >
              {{ $t('home.leadViewList.empty') }}
            </BEmptyBox>
          </template>
          <template v-else>
            <LeadViewCard
              v-for="leadView in leadViews"
              :key="leadView.id"
              :lead-view="leadView"
              @edit="() => handleLeadViewEdit(leadView)"
              @after-delete="handleAfterDelete"
            />
            <BEmptyBox
              v-for="i in 3 - leadViews.length"
              :key="i"
              class="empty-lead-view-card"
              display-only
            />
          </template>
        </template>
      </div>
    </div>
    <BBtn
      outline
      type="primary"
      class="search-button"
      @click="handleClickSearch"
    >
      <BElementIcon
        name="search"
        size="large"
      />
      {{ $t('home.leadViewList.search') }}
    </BBtn>
    <LeadViewUpdateModal
      :lead-view="selectedLeadView"
      @after-update="handleAfterUpdate"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref } from 'vue';
import type { LeadView } from '@/api/openapi';
import { LeadViewApiService } from '@/api/user/resources/lead_view';
import { useModal } from '@/composable/modal';
import { useWait } from '@/composable/vue-wait';
import BEmptyBox from '@/plugins/biscuet-materials/components/molecules/BEmptyBox.vue';
import LeadViewUpdateModal from '../general/modal/leadView/LeadViewUpdateModal.vue';
import LeadViewCard from './LeadViewCard.vue';

const api = new LeadViewApiService();
const { doActionWithWait, wait } = useWait();
const { showModal } = useModal();

const leadViews = ref<LeadView[]>([]);
const selectedLeadView = ref<LeadView>(undefined);
const loading = computed(() => {
  return wait.is('fetchRecentlyMostUsedLeadViews');
});

onBeforeMount(async () => {
  await fetchRecentlyMostUsedLeadViews();
});

const fetchRecentlyMostUsedLeadViews = async () => {
  await doActionWithWait('fetchRecentlyMostUsedLeadViews', async () => {
    const { data } = await api.getRecentlyMostUsedLeadViews();
    leadViews.value = data.leadViews;
  });
};

const handleLeadViewEdit = (leadView: LeadView) => {
  selectedLeadView.value = leadView;
  showModal('leadViewUpdateModal');
};
const handleAfterUpdate = async () => {
  selectedLeadView.value = undefined;
  await fetchRecentlyMostUsedLeadViews();
};
const handleAfterDelete = async () => {
  await fetchRecentlyMostUsedLeadViews();
};

const handleClickSearch = () => {
  console.log('handleClickSearch');
};
</script>

<style lang="scss" scoped>
$lead-view-card-height: 140px;
$lead-view-card-min-width: 280px;
.lead-view-list-title {
  padding-bottom: $basespace-200;
  .b-icon {
    padding-right: $basespace-150;
  }
}
.lead-view-list {
  @include m-flex-center;

  .scroll-container {
    flex: 1;
    overflow-x: scroll;
  }
  .list-container {
    display: flex;
    flex-wrap: nowrap;

    .empty-lead-view-list {
      @include m-flex-center;
      width: 100%;
      height: $lead-view-card-height;
    }
    .lead-view-card, .empty-lead-view-card {
      height: $lead-view-card-height;
      width: calc(100% / 3 - $basespace-100);
      min-width: $lead-view-card-min-width;
      &:not(:last-child) {
        margin-right: $basespace-100;
      }
    }
    .lead-view-card :deep(.title) {
      @include m-truncate-text-multi-line(4);
    }
  }

  .search-button {
    @include m-break-word-pre-wrap;
    height: $lead-view-card-height;
    width: 140px;
    margin-left: $basespace-200;
    line-height: 1.2;
    :deep(.inner) {
      display: flex;
      flex-direction: column;
    }
    .b-icon {
      padding-bottom: $basespace-100;
    }
  }
  .b-modal {
    z-index: 10000;
  }
}
</style>

