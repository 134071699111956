<template>
  <BModal
    :modal-name="MODAL_NAME"
    width="800px"
  >
    <BHeightAdjuster>
      <BModalHeader
        :title="title"
        center
        @modal-close="handleModalClose"
      />
      <ElTabs
        v-model="activeTab"
        lazy
      >
        <ElTabPane
          key="immediateExportSetting"
          name="immediateExportSetting"
          :label="i18n.t('leadExport.immediateExport')"
        >
          <ImmediateExport
            :number-of-target-leads="numberOfTargetLeads"
            :number-of-leads="numberOfLeads"
            :lead-list-filter-params="leadListFilterParams"
            @after-export="handleAfterExport"
          />
        </ElTabPane>
        <ElTabPane
          key="scheduledExportSetting"
          name="scheduledExportSetting"
          :label="i18n.t('leadExport.scheduledExportSetting')"
        >
          <ScheduledExportSetting
            :number-of-target-leads="numberOfTargetLeads"
            :number-of-leads="numberOfLeads"
            :lead-list-filter-params="leadListFilterParams"
          />
        </ElTabPane>
        <ElTabPane
          key="history"
          name="history"
          :label="i18n.t('leadExport.history')"
        >
          <ExportHistories :refetch-histories="refetchHistories" />
        </ElTabPane>
      </ElTabs>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts" setup>
import { inject, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useToast } from '@/composable/toast';
import { KEY_OF_MODAL } from '@/injection-keys';
import ExportHistories from './leadExportModal/ExportHistories.vue';
import ImmediateExport from './leadExportModal/ImmediateExport.vue';
import ScheduledExportSetting from './leadExportModal/ScheduledExportSetting.vue';
import type { TLeadListProps } from './leadExportModal/types';

type TProps = TLeadListProps;

defineProps<TProps>();

const MODAL_NAME = 'LeadExportModal';
const i18n = useI18n();
const title = i18n.t('leadExport.title');
const { successToast } = useToast();

const activeTab = ref<'immediateExportSetting' | 'scheduledExportSetting' | 'history'>('immediateExportSetting');
const refetchHistories = ref(false);

const modal = inject(KEY_OF_MODAL);
const handleModalClose = () => {
  modal.hide(MODAL_NAME);
};
const handleAfterExport = async () => {
  refetchHistories.value = true;
  successToast(i18n.t('general.started.to', { action: i18n.t('leadExport.title') }));
  activeTab.value = 'history';
  await nextTick();
  refetchHistories.value = false;
};
</script>

<style lang="scss" scoped>
:deep(.b-modal-footer) {
  @include m-flex-center;
}
:deep(.b-modal-body) {
  padding-bottom: $basespace-200;
}
.el-tabs {
  height: calc(100% - 89px); // ヘッダーの高さを除く

  :deep(.el-tabs__nav-scroll) {
    padding: 0 40px;
  }

  .el-tab-pane {
    height: 100%;

    :deep(.el-tabs__content) {
      height: 100%;
    }
  }
}
.scheduled-export-setting, .immediate-export {
  height: 100%;

  :deep(.b-modal-body) {
    height: calc(100% - 76px); // フッターの高さを除く
  }
}
:deep(.form-items .el-descriptions__label.el-descriptions__cell.is-bordered-label) {
  @include m-fixed-width(130px);
}
</style>
