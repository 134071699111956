<template>
  <BContent
    v-loading="$wait.is(['getSequenceMastersWait', KEY_OF_DELETE_SEQUENCE_MASTER_WAIT, 'getClientLimitSettingWait', KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT])"
    class="content"
  >
    <template #navbar>
      <div class="navbar-left">
        <span class="page-title fs-400 fw-bold">{{ $t('sequence.title') }}</span>
        <button
          type="button"
          class="create-btn"
          @click="moveToSequenceNew"
        >
          <BCustomIcon
            icon-class="b-sequence"
            type="white"
            size="medium"
          />
          <span class="ml-300">{{ $t('sequence.create') }}</span>
        </button>
      </div>
      <div class="navbar-right">
        <BPagination
          v-model="currentPage"
          :length="allPageCount"
          :total-visible="5"
        />
      </div>
    </template>
    <div class="select-wrapper mt-300 mb-200 pl-600">
      <SelectUser
        v-model="selectedUserId"
      />
    </div>
    <div class="mt-50">
      <SequenceMasterList
        :sequence-masters="sequenceMasters"
        @after-delete="handleAfterSequenceMasterAction"
        @after-change-owner="handleAfterSequenceMasterAction"
      />
    </div>
  </BContent>
</template>

<script lang="ts" setup>
import { computed, inject, ref, watch, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { GetSequenceMasters200Response, PaginationMeta, SequenceMaster, SequenceMasterApiGetSequenceMastersRequest } from '@/api/openapi';
import SequenceMasterList, { KEY_OF_CHANGE_SEQUENCE_MASTER_OWNER_WAIT, KEY_OF_DELETE_SEQUENCE_MASTER_WAIT } from '@/components/organisms/user/sequences/SequenceMasterList.vue';
import { useCurrentUser } from '@/composable/user/user/users';
import { i18nGlobal as i18n } from '@/i18n';
import { KEY_OF_WAIT } from '@/injection-keys';
import { bitterAlert } from '@/plugins/BBitterAlert';
import SelectUser from '@/plugins/biscuet-materials/components/molecules/SelectUser.vue';
import { key } from '@/store/index';

const store = useStore(key);
const wait = inject(KEY_OF_WAIT);
const router = useRouter();

const sequenceMasters = ref<SequenceMaster[]>([]);
const currentPage = ref(1);
const meta = ref<PaginationMeta>({
  totalPages: 1,
  totalCount: 0,
  limitValue: 0,
});

const { currentUser, fetchCurrentUser } = useCurrentUser();
const selectedUserId = ref<number | undefined>();

onBeforeMount(async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
  await loadSequenceMasters(currentPage.value);
});

const loadSequenceMasters = async (currentPage: number) => {
  const ownerId = selectedUserId.value;
  const request: SequenceMasterApiGetSequenceMastersRequest = {
    currentPage,
    ownerId,
  };

  wait.start('getSequenceMastersWait');
  const { data } = await store.dispatch('user/getSequenceMastersAction', { request });
  const { sequenceMasters: dataSequenceMasters, meta: dataMeta } = data as GetSequenceMasters200Response;
  sequenceMasters.value = dataSequenceMasters;
  meta.value = dataMeta;
  wait.end('getSequenceMastersWait');
};

const allPageCount = computed(() => meta.value.totalPages);

watch(() => currentPage.value, (newValue) => {
  loadSequenceMasters(newValue);
});

watch(() => selectedUserId.value, (newValue) => {
  loadSequenceMasters(currentPage.value);
});

const moveToSequenceNew = async () => {
  wait.start('getClientLimitSettingWait');
  const { sequenceLimit } = await store.dispatch('user/getClientLimitSettingAction');
  if (meta.value.totalCount >= sequenceLimit) {
    await bitterAlert.show({
      title: i18n.t('general.error'),
      text: i18n.t('sequence.exceededSequenceLimit'),
    });
    
    return;
  }
  wait.end('getClientLimitSettingWait');
  router.push({ name: 'SequenceNew' });
};

const handleAfterSequenceMasterAction = () => {
  loadSequenceMasters(currentPage.value);
};
</script>

<style lang="scss" scoped>
.navbar-left {
  display: flex;
  height: 100%;
  margin-top: 3px;
  .create-btn {
    position: relative;
    width: 150px;
    height: 37px;
    margin-top: 5px;
    margin-left: 30px;
    color: $textcolor-white;
    background-color: $basecolor-primary;
    border-radius: 20px;
    outline: none;
    .b-icon {
      position: absolute;
      top: 10px;
      left: 15px;
    }
  }
  .page-title {
    display: block;
    margin-top: 12px;
  }
}

.navbar-right {
  max-width: 300px;
  margin-left: auto;
  margin-top: 3px;
}
.select-wrapper {
  position: sticky;
  left: 0;
  z-index: 3; // SelectUserがtableの下に潜らないようにする
}

.content {
  position: relative;
}
</style>
