<template>
  <BContentBox v-loading="$wait.is('fetchCurrentUser')">
    <template #content>
      <div class="form-items">
        <div class="description">
          {{ $t('corpSettingApi.account.exportSetting.gcp.description') }}
        </div>
        <h3 class="title">
          {{ $t('corpSettingApi.account.exportSetting.gcp.title') }}
        </h3>
        <div v-loading="$wait.is(['fetchGcpSettingWait', 'putGcpSettingWait', 'deleteGcpSettingWait'])">
          <BListItem v-if="hasGcpSetting">
            {{ $t('corpSettingApi.account.exportSetting.gcp.connected') }}
          </BListItem>
          <BListItem v-else>
            <template #header>
              <span>{{ $t('corpSettingApi.account.exportSetting.gcp.jsonKey') }}</span>
            </template>
            <BInput
              v-model="jsonKey"
              v-model:valid="jsonKeyValid"
              :rules="[requiredRule]"
              type="password"
              required
              validation
            />
          </BListItem>
          <BListItem>
            <template #header>
              <span>
                {{ $t('corpSettingApi.account.exportSetting.gcp.email') }}
                <BHelp
                  :content="t('corpSettingApi.account.exportSetting.gcp.emailHelp')"
                />
              </span>
            </template>
            <BInput
              v-model="email"
              v-model:valid="emailValid"
              :rules="[requiredRule, emailRule]"
              type="text"
              required
              validation
            />
          </BListItem>
          <template v-if="hasGcpSetting">
            <BListItem>
              <BBtn
                type="primary"
                :disabled="!emailValid"
                @click="handleUpdateClick"
              >
                {{ $t('general.save.text') }}
              </BBtn>
            </BListItem>
            <BListItem>
              <BBtn
                type="error"
                outline
                @click="handleDisconnectClick"
              >
                {{ $t('corpSettingApi.account.exportSetting.gcp.disconnect') }}
              </BBtn>
            </BListItem>
          </template>
          <BListItem v-else>
            <BBtn
              type="primary"
              :disabled="!formValid"
              @click="handleConnectClick"
            >
              {{ $t('corpSettingApi.account.exportSetting.gcp.connect') }}
            </BBtn>
          </BListItem>
        </div>
      </div>
    </template>
  </BContentBox>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { GcpSettingApiService } from '@/api/user/resources/gcp_setting';
import { useInputValidations } from '@/composable/input-validations';
import { useConfirm } from '@/composable/message-dialog';
import { useToast } from '@/composable/toast';
import { useWait } from '@/composable/vue-wait';

const { requiredRule, emailRule } = useInputValidations();
const { doActionWithWait } = useWait();
const { confirm } = useConfirm();
const { t } = useI18n();
const { successToast } = useToast();
const api = new GcpSettingApiService();

const jsonKey = ref('');
const jsonKeyValid = ref(false);
const email = ref('');
const emailValid = ref(false);
const formValid = computed(() => jsonKeyValid.value && emailValid.value);
const hasGcpSetting = ref(false);

onBeforeMount(async () => {
  await fetchGcpSetting();
});

const handleDisconnectClick = async () => {
  const confirmed = await confirm({
    message: t('corpSettingApi.account.exportSetting.gcp.disconnectConfirmText'),
  });
  if (!confirmed) return;

  await doActionWithWait('deleteGcpSettingWait', async () => {
    await api.deleteGcpSetting();
    hasGcpSetting.value = false;
    email.value = '';
    successToast(t('corpSettingApi.account.exportSetting.gcp.disconnectSuccessText'));
  });
};

const handleConnectClick = async () => {
  await doActionWithWait('putGcpSettingWait', async () => {
    await api.putGcpSetting({
      request: {
        putGcpSettingRequest: {
          jsonKey: jsonKey.value,
          email: email.value,
        },
      },
    });
    hasGcpSetting.value = true;
    jsonKey.value = '';
    successToast(t('corpSettingApi.account.exportSetting.gcp.connectSuccessText'));
  });
};

const handleUpdateClick = async () => {
  await doActionWithWait('patchGcpSettingWait', async () => {
    await api.patchGcpSetting({
      request: {
        patchGcpSettingRequest: {
          email: email.value,
        },
      },
    });
    successToast(t('corpSettingApi.account.exportSetting.gcp.connectSuccessText'));
  });
};

const fetchGcpSetting = async () => {
  await doActionWithWait('fetchGcpSettingWait', async () => {
    const { data: { gcpSetting } } = await api.getGcpSetting({
      errorHandlers: {
        404: () => {
          hasGcpSetting.value = false;
        },
      },
    });
    email.value = gcpSetting?.email;
    hasGcpSetting.value = gcpSetting?.id !== null;
  });
};
</script>

<style lang="scss" scoped>
.description {
  padding-bottom: $basespace-400;
}
.title {
  padding-bottom: $basespace-400;
}
.b-list-item {
  width: 40%;
  padding-bottom: $basespace-400;
}
.form-items {
  padding: 0;
}
</style>
