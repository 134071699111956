import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  GcpSettingApi,
  GcpSettingApiPutGcpSettingRequest,
  GcpSettingApiPatchGcpSettingRequest,
  GetGcpSetting200Response,
} from '@/api/openapi';

export class GcpSettingApiService extends ApiService {
  api = new GcpSettingApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getGcpSetting({
    options,
    errorHandlers,
  }: ApiArguments<never> = {}): Promise<AxiosResponse<GetGcpSetting200Response>> {
    return this.checkResponse<GetGcpSetting200Response>(
      this.api.getGcpSetting(options),
      errorHandlers,
    );
  }

  async putGcpSetting({
    request,
    options,
    errorHandlers,
  }: ApiArguments<GcpSettingApiPutGcpSettingRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.putGcpSetting(request, options),
      errorHandlers,
    );
  }

  async patchGcpSetting({
    request,
    options,
    errorHandlers,
  }: ApiArguments<GcpSettingApiPatchGcpSettingRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.patchGcpSetting(request, options),
      errorHandlers,
    );
  }

  async deleteGcpSetting({
    options,
    errorHandlers,
  }: ApiArguments<never> = {}): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.deleteGcpSetting(options),
      errorHandlers,
    );
  }
}
