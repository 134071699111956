/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetGcpSetting200Response } from '../models';
// @ts-ignore
import { PatchGcpSettingRequest } from '../models';
// @ts-ignore
import { PutGcpSettingRequest } from '../models';
/**
 * GcpSettingApi - axios parameter creator
 * @export
 */
export const GcpSettingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * GCP設定削除API
         * @summary GCP設定削除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGcpSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/gcp_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GCP設定取得API
         * @summary GCP設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGcpSetting: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/gcp_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GCP設定更新API
         * @summary GCP設定更新API
         * @param {PatchGcpSettingRequest} [patchGcpSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGcpSetting: async (patchGcpSettingRequest?: PatchGcpSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/gcp_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchGcpSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * GCP設定作成API
         * @summary GCP設定作成API
         * @param {PutGcpSettingRequest} [putGcpSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGcpSetting: async (putGcpSettingRequest?: PutGcpSettingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/gcp_setting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putGcpSettingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GcpSettingApi - functional programming interface
 * @export
 */
export const GcpSettingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GcpSettingApiAxiosParamCreator(configuration)
    return {
        /**
         * GCP設定削除API
         * @summary GCP設定削除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGcpSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGcpSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GCP設定取得API
         * @summary GCP設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGcpSetting(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetGcpSetting200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGcpSetting(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GCP設定更新API
         * @summary GCP設定更新API
         * @param {PatchGcpSettingRequest} [patchGcpSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchGcpSetting(patchGcpSettingRequest?: PatchGcpSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchGcpSetting(patchGcpSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * GCP設定作成API
         * @summary GCP設定作成API
         * @param {PutGcpSettingRequest} [putGcpSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGcpSetting(putGcpSettingRequest?: PutGcpSettingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGcpSetting(putGcpSettingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GcpSettingApi - factory interface
 * @export
 */
export const GcpSettingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GcpSettingApiFp(configuration)
    return {
        /**
         * GCP設定削除API
         * @summary GCP設定削除API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGcpSetting(options?: any): AxiosPromise<void> {
            return localVarFp.deleteGcpSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * GCP設定取得API
         * @summary GCP設定取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGcpSetting(options?: any): AxiosPromise<GetGcpSetting200Response> {
            return localVarFp.getGcpSetting(options).then((request) => request(axios, basePath));
        },
        /**
         * GCP設定更新API
         * @summary GCP設定更新API
         * @param {PatchGcpSettingRequest} [patchGcpSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchGcpSetting(patchGcpSettingRequest?: PatchGcpSettingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchGcpSetting(patchGcpSettingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * GCP設定作成API
         * @summary GCP設定作成API
         * @param {PutGcpSettingRequest} [putGcpSettingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGcpSetting(putGcpSettingRequest?: PutGcpSettingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putGcpSetting(putGcpSettingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GcpSettingApi - interface
 * @export
 * @interface GcpSettingApi
 */
export interface GcpSettingApiInterface {
    /**
     * GCP設定削除API
     * @summary GCP設定削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApiInterface
     */
    deleteGcpSetting(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * GCP設定取得API
     * @summary GCP設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApiInterface
     */
    getGcpSetting(options?: AxiosRequestConfig): AxiosPromise<GetGcpSetting200Response>;

    /**
     * GCP設定更新API
     * @summary GCP設定更新API
     * @param {PatchGcpSettingRequest} [patchGcpSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApiInterface
     */
    patchGcpSetting(patchGcpSettingRequest?: PatchGcpSettingRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * GCP設定作成API
     * @summary GCP設定作成API
     * @param {PutGcpSettingRequest} [putGcpSettingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApiInterface
     */
    putGcpSetting(putGcpSettingRequest?: PutGcpSettingRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for patchGcpSetting operation in GcpSettingApi.
 * @export
 * @interface GcpSettingApiPatchGcpSettingRequest
 */
export interface GcpSettingApiPatchGcpSettingRequest {
    /**
     * 
     * @type {PatchGcpSettingRequest}
     * @memberof GcpSettingApiPatchGcpSetting
     */
    readonly patchGcpSettingRequest?: PatchGcpSettingRequest
}

/**
 * Request parameters for putGcpSetting operation in GcpSettingApi.
 * @export
 * @interface GcpSettingApiPutGcpSettingRequest
 */
export interface GcpSettingApiPutGcpSettingRequest {
    /**
     * 
     * @type {PutGcpSettingRequest}
     * @memberof GcpSettingApiPutGcpSetting
     */
    readonly putGcpSettingRequest?: PutGcpSettingRequest
}

/**
 * GcpSettingApi - object-oriented interface
 * @export
 * @class GcpSettingApi
 * @extends {BaseAPI}
 */
export class GcpSettingApi extends BaseAPI implements GcpSettingApiInterface {
    /**
     * GCP設定削除API
     * @summary GCP設定削除API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApi
     */
    public deleteGcpSetting(options?: AxiosRequestConfig) {
        return GcpSettingApiFp(this.configuration).deleteGcpSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GCP設定取得API
     * @summary GCP設定取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApi
     */
    public getGcpSetting(options?: AxiosRequestConfig) {
        return GcpSettingApiFp(this.configuration).getGcpSetting(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GCP設定更新API
     * @summary GCP設定更新API
     * @param {GcpSettingApiPatchGcpSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApi
     */
    public patchGcpSetting(requestParameters: GcpSettingApiPatchGcpSettingRequest = {}, options?: AxiosRequestConfig) {
        return GcpSettingApiFp(this.configuration).patchGcpSetting(requestParameters.patchGcpSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * GCP設定作成API
     * @summary GCP設定作成API
     * @param {GcpSettingApiPutGcpSettingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GcpSettingApi
     */
    public putGcpSetting(requestParameters: GcpSettingApiPutGcpSettingRequest = {}, options?: AxiosRequestConfig) {
        return GcpSettingApiFp(this.configuration).putGcpSetting(requestParameters.putGcpSettingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
