<template>
  <div
    v-loading="loading || currentTab == null"
    class="corp-setting-main"
  >
    <div class="corp-setting-sidebar">
      <BHeightAdjuster>
        <div class="corp-setting-menu">
          <template
            v-for="(currentTabs, index) in [ tabUsers, tabClients, tabItemSetting, tabSystemSetting, tabApiSetting ]"
            :key="index"
          >
            <div
              v-if="index !== 0 && someTabEnabled(currentTabs)"
              class="hr"
            />
            <template
              v-for="item in currentTabs"
              :key="item.tabType"
            >
              <div
                v-show="item.tabAvailable"
                class="list-item"
                :class="{ active: isCurrentTab(item.tabType) }"
                @click="() => setTabsToPath(item.tabType)"
              >
                {{ item.text }}
              </div>
            </template>
          </template>
        </div>
      </BHeightAdjuster>
    </div>
    <div class="corp-setting-content">
      <div class="modal-tab-box">
        <div class="tab-header">
          <BLayout>
            <template v-if="currentTab in subTabs">
              <div
                v-for="(tab, index) in subTabs[currentTab]"
                v-show="tab.tabAvailable"
                :key="index"
                class="tab-item"
                :class="{ active: isCurrentSubTab(tab.tabType) }"
                @click="() => setTabsToPath(currentTab, tab.tabType)"
              >
                {{ tab.tabTitle }}
              </div>
            </template>
            <template v-else-if="currentTabTitle">
              <div
                class="tab-item active"
              >
                {{ currentTabTitle }}
              </div>
            </template>
            <template v-if="currentTab == 'customField'">
              <div class="header-submenu-area">
                <div
                  class="header-submenu"
                >
                  <BTooltip
                    top
                    :content="$t('customField.import')"
                  >
                    <div>
                      <BMenu
                        v-model="isImportMenuVisible"
                        placement="bottom"
                      >
                        <template #activator>
                          <BCustomIcon
                            class="mr-100 ml-100"
                            size="small"
                            icon-class="b-import"
                            hover
                          />
                        </template>
                        <BCard full>
                          <div class="py-100">
                            <BBtn
                              flat
                              fit
                              size="small"
                              @click="openModal"
                            >
                              <span>{{ $t('customField.importSelect.excel') }}</span>
                            </BBtn>
                          </div>
                        </BCard>
                      </BMenu>
                    </div>
                  </BTooltip>
                  <BTooltip
                    v-if="isAvailableSalesforceLinkFeature"
                    top
                    :content="$t('customField.export')"
                  >
                    <div>
                      <BMenu
                        v-model="isExportMenuVisible"
                        placement="bottom"
                      >
                        <template #activator>
                          <BCustomIcon
                            class="mr-100 ml-100"
                            size="small"
                            icon-class="b-export"
                            hover
                          />
                        </template>
                        <BCard full>
                          <div class="py-100">
                            <BBtn
                              flat
                              fit
                              size="small"
                              @click="exportSalesforceLeadObjects"
                            >
                              <span>{{ $t('customField.exportSelect.sfLead') }}</span>
                            </BBtn>
                            <BBtn
                              flat
                              fit
                              size="small"
                              @click="exportSalesforceContactObjects"
                            >
                              <span>{{ $t('customField.exportSelect.sfContact') }}</span>
                            </BBtn>
                            <BBtn
                              flat
                              fit
                              size="small"
                              @click="exportSalesforceAccountObjects"
                            >
                              <span>{{ $t('customField.exportSelect.sfAccount') }}</span>
                            </BBtn>
                          </div>
                        </BCard>
                      </BMenu>
                    </div>
                  </BTooltip>
                </div>
              </div>
            </template>
          </BLayout>
        </div>
        <div class="tab-content">
          <template v-if="isCurrentTab('userSetting')">
            <UserProfile
              v-if="isCurrentSubTab('profile')"
              class="tab-contents"
            />
            <UserEmail
              v-else-if="isCurrentSubTab('userEmail')"
              class="tab-contents"
            />
            <UserSecurity
              v-else-if="isCurrentSubTab('security')"
              class="tab-contents"
            />
          </template>
          <template v-if="isCurrentTab('account')">
            <ExportSetting
              v-if="isCurrentSubTab('exportSetting')"
              class="tab-contents"
            />
            <Account
              v-else-if="isCurrentSubTab('setting')"
              class="tab-contents"
            />
          </template>
          <Member
            v-if="isCurrentTab('member')"
            class="tab-contents"
          />
          <LeadStageItem
            v-if="isCurrentTab('leadStage')"
            class="tab-contents"
          />
          <LeadSourceItem
            v-if="isCurrentTab('leadSource')"
            class="tab-contents"
          />
          <FreeTagItem
            v-if="isCurrentTab('freeTag')"
            class="tab-contents"
          />
          <CustomFieldItem
            v-if="isCurrentTab('customField')"
            ref="customFieldItem"
            class="tab-contents"
          />
          <NextActionItem
            v-if="isCurrentTab('nextAction')"
            class="tab-contents"
          />
          <CallResultItem
            v-if="isCurrentTab('callResult')"
            class="tab-contents"
          />
          <CustomActionItem
            v-if="isCurrentTab('customAction')"
            class="tab-contents"
          />
          <QuickTexts
            v-if="isCurrentTab('quickText')"
            class="tab-contents"
          />
          <SequenceSetting
            v-if="isCurrentTab('sequenceSetting')"
            class="tab-contents"
          />
          <SalesforceApi
            v-if="isCurrentTab('salesforce') && isCurrentSubTab('salesforceRelationInfo')"
            :salesforce-api-info="salesforceApiInfo"
            class="tab-contents"
            @reload-salesforce-api-info="fetchSalesforceApiInfo"
          />
          <TodoSync
            v-if="isCurrentTab('salesforce') && isCurrentSubTab('todoSync')"
            class="tab-contents"
          />
          <SalesforceApiMap
            v-if="isCurrentTab('salesforce') && isCurrentSubTab('salesforceRelationMap')"
            class="tab-contents"
          />
          <SalesforceApiUser
            v-if="isCurrentTab('salesforce') && isCurrentSubTab('salesforceRelationUser')"
            class="tab-contents"
          />
          <SalesforceErrorLogs
            v-if="isCurrentTab('salesforce') && isCurrentSubTab('salesforceErrorLogs')"
            class="tab-contents"
          />
          <SalesforceScheduleImport
            v-if="isCurrentTab('salesforce') && isCurrentSubTab('salesforceScheduleImport')"
            class="tab-contents"
          />
          <MiitelApi
            v-if="isCurrentTab('miitel')"
            class="tab-contents"
          />
          <ZoomApi
            v-if="isCurrentTab('zoom')"
            class="tab-contents"
          />
          <BoxilApi
            v-if="isCurrentTab('boxil')"
            class="tab-contents"
          />
        </div>
      </div>
      <CustomImportModal @refresh="refreshCustomFieldItems" />
    </div>
  </div>
</template>

<script lang="ts">
import { camelCase, kebabCase } from 'lodash';
import { defineComponent } from 'vue';
import Api from '@/api/user';
import SalesforceSettingApi from '@/api/user/resources/salesforce/salesforce_setting';
import CustomImportModal from '@/components/organisms/user/general/modal/CustomImportModal.vue';
import Account from '@/components/organisms/user/setting/account/Account.vue';
import ExportSetting from '@/components/organisms/user/setting/account/ExportSetting.vue';
import BoxilApi from '@/components/organisms/user/setting/api/BoxilApi.vue';
import MiitelApi from '@/components/organisms/user/setting/api/MiitelApi.vue';
import SalesforceApi from '@/components/organisms/user/setting/api/SalesforceApi.vue';
import SalesforceApiMap from '@/components/organisms/user/setting/api/SalesforceApiMap.vue';
import SalesforceApiUser from '@/components/organisms/user/setting/api/SalesforceApiUser.vue';
import SalesforceErrorLogs from '@/components/organisms/user/setting/api/SalesforceErrorLogs.vue';
import SalesforceScheduleImport from '@/components/organisms/user/setting/api/SalesforceScheduleImport.vue';
import TodoSync from '@/components/organisms/user/setting/api/TodoSync.vue';
import ZoomApi from '@/components/organisms/user/setting/api/ZoomApi.vue';
import CallResultItem from '@/components/organisms/user/setting/item/CallResultItem.vue';
import CustomActionItem from '@/components/organisms/user/setting/item/CustomActionItem.vue';
import CustomFieldItem from '@/components/organisms/user/setting/item/CustomFieldItem.vue';
import FreeTagItem from '@/components/organisms/user/setting/item/FreeTagItem.vue';
import LeadSourceItem from '@/components/organisms/user/setting/item/LeadSourceItem.vue';
import LeadStageItem from '@/components/organisms/user/setting/item/LeadStageItem.vue';
import NextActionItem from '@/components/organisms/user/setting/item/NextActionItem.vue';
import QuickTexts from '@/components/organisms/user/setting/item/QuickTexts.vue';
import Member from '@/components/organisms/user/setting/member/Member.vue';
import SequenceSetting from '@/components/organisms/user/setting/systemSetting/SequenceSetting.vue';
import UserEmail from '@/components/organisms/user/setting/userSetting/UserEmail.vue';
import UserProfile from '@/components/organisms/user/setting/userSetting/UserProfile.vue';
import UserSecurity from '@/components/organisms/user/setting/userSetting/UserSecurity.vue';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { useCurrentUser } from '@/composable/user/user/users';

export default defineComponent({
  components: {
    SalesforceApi,
    TodoSync,
    SalesforceApiMap,
    SalesforceErrorLogs,
    SalesforceScheduleImport,
    SalesforceApiUser,
    MiitelApi,
    BoxilApi,
    ZoomApi,
    Account,
    ExportSetting,
    UserProfile,
    UserEmail,
    UserSecurity,
    Member,
    LeadSourceItem,
    FreeTagItem,
    CustomFieldItem,
    NextActionItem,
    CallResultItem,
    LeadStageItem,
    CustomActionItem,
    SequenceSetting,
    CustomImportModal,
    QuickTexts,
  },
  setup() {
    const {
      isAvailableSalesforceLinkFeature,
      isAvailableDirectMailFeature,
      isAvailableSequenceFeature,
      isAvailableMfaFeature,
      isAvailableReservedLeadExportFeature,
      promiseOfFetchAvailableFeatures,
    } = useAvailableFeatureCheck();
    const { currentUser, fetchCurrentUser } = useCurrentUser();
    return {
      isAvailableSalesforceLinkFeature,
      isAvailableDirectMailFeature,
      isAvailableSequenceFeature,
      isAvailableMfaFeature,
      isAvailableReservedLeadExportFeature,
      promiseOfFetchAvailableFeatures,
      currentUser,
      fetchCurrentUser,
    };
  },
  data () {
    return {
      loading: false,
      salesforceApiInfo: {},
      currentTab: null,
      currentSubTab: null,
      isImportMenuVisible: false,
      isExportMenuVisible: false,
    };
  },
  computed: {
    isSalesforceApiUsable () {
      return this.isAvailableSalesforceLinkFeature && this.salesforceSetting?.connectStatus === 'connected';
    },
    salesforceSetting() {
      return this.salesforceApiInfo.data?.salesforceSetting;
    },
    subTabs() {
      const listColumns = [];
      listColumns.push({ tabType: 'salesforceRelationInfo', tabTitle: this.$t('corpSettingApi.salesforce.subTabs.relationInfo'), tabAvailable: true });
      if (this.isSalesforceApiUsable) {
        listColumns.push({ tabType: 'todoSync', tabTitle: this.$t('corpSettingApi.salesforce.subTabs.todoSync'), tabAvailable: true });
        listColumns.push({ tabType: 'salesforceRelationMap', tabTitle: this.$t('corpSettingApi.salesforce.subTabs.relationMap'), tabAvailable: true });
        listColumns.push({ tabType: 'salesforceErrorLogs', tabTitle: this.$t('corpSettingApi.salesforce.subTabs.errorLogs'), tabAvailable: true });
        listColumns.push({ tabType: 'salesforceRelationUser', tabTitle: this.$t('corpSettingApi.salesforce.subTabs.relationUser'), tabAvailable: true });
        listColumns.push({ tabType: 'salesforceScheduleImport', tabTitle: this.$t('corpSettingApi.salesforce.subTabs.scheduleImport'), tabAvailable: true });
      }

      return {
        'salesforce': listColumns,
        'userSetting': [
          { tabType: 'profile', tabTitle: this.$t('corpSettingApi.userSetting.subTabs.profile'), tabAvailable: true },
          { tabType: 'userEmail', tabTitle: this.$t('corpSettingApi.userSetting.subTabs.userEmail'), tabAvailable: this.isAvailableDirectMailFeature },
          { tabType: 'security', tabTitle: this.$t('corpSettingApi.userSetting.subTabs.security'), tabAvailable: this.isAvailableMfaFeature },
        ],
        'account': [
          { tabType: 'setting', tabTitle: this.$t('corpSettingApi.account.subTabs.setting'), tabAvailable: true },
          { tabType: 'exportSetting', tabTitle: this.$t('corpSettingApi.account.subTabs.exportSetting'), tabAvailable: this.isAvailableReservedLeadExportFeature },
        ],
      };
    },
    tabApiSetting () {
      const listColumns = [];
      listColumns.push({ text: this.$t('corpSettingApi.tab.miitel.title'), tabType: 'miitel', tabTitle: this.$t('corpSettingApi.api'), tabAvailable: true });
      listColumns.push({ text: this.$t('corpSettingApi.tab.zoom.title'), tabType: 'zoom', tabTitle: this.$t('corpSettingApi.api'), tabAvailable: true });
      //ToDo: BoxilのAPIのクローズが決まったらコードも消す
      listColumns.push({ text: this.$t('corpSettingApi.tab.boxil.title'), tabType: 'boxil', tabTitle: this.$t('corpSettingApi.api'), tabAvailable: false });
      if (this.isAvailableSalesforceLinkFeature) {
        listColumns.push({ text: this.$t('corpSettingApi.tab.salesforce.title'), tabType: 'salesforce', tabTitle: this.$t('corpSettingApi.api'), tabAvailable: true });
      }

      return listColumns;
    },
    tabUsers () {
      return [
        { text: this.$t('corpSettingApi.tab.userSetting.title'), tabType: 'userSetting', tabTitle: '', tabAvailable: true },
      ];
    },
    tabClients () {
      return [
        { text: this.$t('corpSettingApi.tab.member.title'), tabType: 'member', tabTitle: this.$t('corpSettingApi.member'), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.account.title'), tabType: 'account', tabTitle: this.$t('corpSettingApi.user'), tabAvailable: this.isCurrentUserHasOwnerRole },
      ];
    },
    tabItemSetting() {
      return [
        { text: this.$t('corpSettingApi.tab.leadStage.title'), tabType: 'leadStage', tabTitle: this.$t('general.setting.to', { item: this.$t('leadStage.title') }), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.leadSource.title'), tabType: 'leadSource', tabTitle: this.$t('general.setting.to', { item: this.$t('leadSource.title') }), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.freeTag.title'), tabType: 'freeTag', tabTitle: this.$t('general.setting.to', { item: this.$t('freeTag.title') }), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.customField.title'), tabType: 'customField', tabTitle: this.$t('customField.title'), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.nextAction.title'), tabType: 'nextAction', tabTitle: this.$t('general.nextAction'), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.callResult.title'), tabType: 'callResult', tabTitle: this.$t('general.callResult'), tabAvailable: true },
        { text: this.$t('corpSettingApi.tab.customAction.title'), tabType: 'customAction', tabTitle: this.$t('customAction.title'), tabAvailable: true },
        { text: this.$t('quickText.title'), tabType: 'quickText', tabTitle: this.$t('quickText.title'), tabAvailable: this.isCurrentUserHasOwnerRole },
      ];
    },
    tabSystemSetting() {
      const sequenceSettingTabAvailable = this.isCurrentUserHasOwnerRole && this.isAvailableSequenceFeature;
      return [
        { text: this.$t('corpSettingApi.tab.sequenceSetting.title'), tabType: 'sequenceSetting', tabTitle: this.$t('corpSettingApi.tab.sequenceSetting.title'), tabAvailable: sequenceSettingTabAvailable },
      ];
    },
    tabs() {
      return [
        ...this.tabUsers,
        ...this.tabClients,
        ...this.tabApiSetting,
        ...this.tabSystemSetting,
        ...this.tabItemSetting,
      ];
    },
    currentTabTitle() {
      return this.tabs.find(tab => tab.tabType === this.currentTab)?.tabTitle;
    },
    isCurrentUserHasOwnerRole() {
      return this.currentUser?.role === 'owner';
    },
    modalName() {
      return 'customImportModal';
    },
  },
  async mounted() {
    await this.fetchCurrentUser();

    const tab = this.$route.params.tab;
    const subTab = this.$route.params.subTab;
    if (tab === 'salesforce') {
      await this.fetchSalesforceApiInfo();
    }
    const tabType = tab ? camelCase(tab.toString()) : null;
    const subTabType = subTab ? camelCase(subTab.toString()) : null;
    this.setSelectTab(tabType, subTabType);
  },
  methods: {
    async fetchSalesforceApiInfo() {
      try {
        this.loading = true;
        this.salesforceApiInfo = await SalesforceSettingApi.getSalesforceSetting();
      } finally {
        this.loading = false;
      }
    },
    isCurrentTab (tabType) {
      return this.currentTab === tabType;
    },
    isCurrentSubTab (tabType) {
      if (this.currentTab in this.subTabs) {
        return this.currentSubTab === tabType;
      }
      return true;
    },
    setTabsToPath(tabType: string, subTabType?: string) {
      this.$router.push({
        name: 'Setting',
        params: { tab: kebabCase(tabType), subTab: kebabCase(subTabType) },
      });
    },
    isTabAvailable(tabType?: string | null): boolean {
      if (!tabType) return false;

      return this.tabs.find(tab => tab.tabType === tabType)?.tabAvailable || false;
    },
    isSubTabAvailable(tabType?: string | null, subTabType?: string | null): boolean {
      if (!tabType || !subTabType) return false;

      return this.subTabs[tabType]?.find(subTab => subTab.tabType === subTabType)?.tabAvailable || false;
    },
    async setSelectTab(tabType?: string | null, subTabType?: string | null) {
      await this.promiseOfFetchAvailableFeatures;
      // NOTE: invalid/無効なタブにはアクセスさせない
      this.currentTab = this.isTabAvailable(tabType) ? tabType : null;
      this.currentSubTab = this.isSubTabAvailable(this.currentTab, subTabType) ? subTabType : null;
      if (this.currentTab && this.currentSubTab === null && this.currentTab in this.subTabs) {
        // 有効な子タブをアクティブにする
        this.subTabs[this.currentTab].some(subTab => {
          if (this.isSubTabAvailable(this.currentTab, subTab.tabType)) {
            this.currentSubTab = subTab.tabType;
            return true;
          }
        });
        // 有効な子タブがない時は親タブもアクセス不可
        if (this.currentSubTab == null) {
          this.currentTab = null;
        }
      }
      if (this.currentTab == null) {
        this.currentTab = 'userSetting';
      }

      this.$router.replace({
        name: 'Setting',
        params: { tab: kebabCase(this.currentTab), subTab: kebabCase(this.currentSubTab) },
        query: this.$route?.query,
      });
    },
    exportSalesforceLeadObjects() {
      Api.exportLeadObjects({})
        .then((res) => {
          this.downloadExcel(res.data, 'salesforce-lead-objects.xlsx');
        });
    },
    exportSalesforceAccountObjects() {
      Api.exportAccountObjects({})
        .then((res) => {
          this.downloadExcel(res.data, 'salesforce-account-objects.xlsx');
        });
    },
    exportSalesforceContactObjects() {
      Api.exportContactObjects({})
        .then((res) => {
          this.downloadExcel(res.data, 'salesforce-contact-objects.xlsx');
        });
    },
    downloadExcel(excelString, fileName) {
      const blob = new Blob([excelString], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    },
    openModal() {
      this.$modal.show(this.modalName);
    },
    refreshCustomFieldItems() {
      this.$refs.customFieldItem.refresh();
    },
    someTabEnabled(tabs) {
      return tabs.some(tab => tab.tabAvailable);
    },
  },
});
</script>

<style lang="scss" scoped>
$corp-setting-content-margin-top: 100px;
.corp-setting-main {
  position: relative;
  width: 100%;
}

.corp-setting-sidebar {
  @include m-fixed-width($setting-sidebar-width);
  @include m-fixed-height($page-height);
  margin-top: 50px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.06));
  z-index: 8;
  box-shadow: 5px 0 5px -5px $bdcolor-base;
}
.corp-setting-menu {
  margin-top: 8px;

  .list-item {
    @include m-fixed-height($setting-item-height);
    position: relative;
    display: flex;
    align-items: center;
    color: #000000;
    text-decoration: none;
    padding: $basespace-100 $basespace-400;
    margin-bottom: $basespace-50;
    transition: $transition-base;
    cursor: pointer;

    &:hover {
      background-color: $bgcolor-dark;
    }

    &.active {
      transition: none;
      background: linear-gradient(90deg, rgba(43, 192, 184, 0.5) -4%, rgba(43, 192, 184, 0) 100%);
      color: $basecolor-primary;
    }

    &.active::before {
      content: "";
      display: inline-block;
      width: 3px;
      height: 100%;
      background-color: $basecolor-success;
      position: absolute;
      left: 0;
    }
  }

  .hr {
    border-bottom: 1px solid $bdcolor-base;
    margin-bottom: 8px;
    margin-top: 8px;
  }
}
.corp-setting-content {
  position: absolute;
  top: 0;
  left: 250px;
  height: 100%;
  width: calc(100% - 250px);
  margin-top: $corp-setting-content-margin-top;

  .modal-tab-box {
    // TODO: 数値を埋め込まない
    height: calc(100% - $corp-setting-content-margin-top);

    .tab-header {
      // TODO: 数値を埋め込まない
      height: 23px;
      border-bottom: 1px solid $bdcolor-base;

      .tab-item {
        margin: 0 $basespace-600;
        position: relative;
        top: 1px;
        transition: $transition-base;
        font-size: $fontsize-200;
        line-height: 1;
        text-align: center;
        padding: 0 0 6px;
        color: $textcolor-base;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        font-weight: 600;
        min-width: 56px;

        &:hover {
          color: $basecolor-primary;
        }

        &.active {
          color: $basecolor-primary;
          border-color: $basecolor-primary;
        }
      }

      .header-submenu-area {
        position: absolute;
        right: 56px;
        bottom: 20px;

        .header-submenu {
          display: flex;
          justify-content: space-between;
          justify-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }

    .tab-contents {
      height: 100%;
    }
  }

  .tab-content {
    height: calc(100% - 23px);
  }
}
</style>
