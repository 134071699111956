import { inject } from 'vue';
import { KEY_OF_TOAST } from '@/injection-keys';

const useToast = () => {
  const toast = inject(KEY_OF_TOAST);

  const errorToast = (message: string) => {
    toast.show({
      message,
      type: 'error',
    });
  };
  const successToast = (message: string) => {
    toast.show(message);
  };

  return { errorToast, successToast };
};

export {
  useToast,
};
