/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { DayOfWeek } from './day-of-week';

/**
 * リードエクスポート設定 定期実行部分
 * @export
 * @interface PartLeadExportSettingScheduled
 */
export interface PartLeadExportSettingScheduled {
    /**
     * 
     * @type {string}
     * @memberof PartLeadExportSettingScheduled
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof PartLeadExportSettingScheduled
     */
    'interval': PartLeadExportSettingScheduledIntervalEnum;
    /**
     * 
     * @type {Array<DayOfWeek>}
     * @memberof PartLeadExportSettingScheduled
     */
    'daysOfWeek': Array<DayOfWeek> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof PartLeadExportSettingScheduled
     */
    'daysOfMonth': Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof PartLeadExportSettingScheduled
     */
    'time': string;
}

export const PartLeadExportSettingScheduledIntervalEnum = {
    Daily: 'daily',
    Weekly: 'weekly',
    Monthly: 'monthly'
} as const;

export type PartLeadExportSettingScheduledIntervalEnum = typeof PartLeadExportSettingScheduledIntervalEnum[keyof typeof PartLeadExportSettingScheduledIntervalEnum];


