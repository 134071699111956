<template>
  <div
    class="lead-view-card"
    @click="handleClickLeadView"
  >
    <div class="content">
      <div class="title">
        {{ leadView.name }}
      </div>
      <div class="created-at">
        {{ updatedAt }}
      </div>
    </div>
    <div class="user-info">
      <BUserTooltip
        v-if="leadView.owner"
        :image-url="leadView.owner.avatarUrl"
        :name="leadView.owner.name"
        :user-id="leadView.owner.id"
        :size="36"
      />
      <BEmptyBox
        v-else
        class="empty-box"
        display-only
      />
    </div>
    <div
      class="actions"
      @click.stop
    >
      <BMoreMenu
        trigger="hover"
        z-index100
      >
        <BBtn
          flat
          fit
          size="small"
          @click="handleEditClick"
        >
          {{
            $t('general.edit.do')
          }}
        </BBtn>
        <BPopover
          v-if="leadView.isUsedByReservedBulkMail"
          placement="top"
          width="240"
          trigger="hover"
        >
          <template #reference>
            <div>
              <BBtn
                flat
                fit
                size="small"
                disabled
              >
                {{ $t('general.delete.do') }}
              </BBtn>
            </div>
          </template>
          <div>{{ $t('mail.alreadyUsedReserve') }}</div>
        </BPopover>
        <BBtn
          v-else
          flat
          fit
          size="small"
          @click="handleDeleteClick"
        >
          {{ $t('general.delete.do') }}
        </BBtn>
      </BMoreMenu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useLink } from 'vue-router';
import type { LeadView } from '@/api/openapi';
import { LeadViewApiService } from '@/api/user/resources/lead_view';
import { LeadViewLogApiService } from '@/api/user/resources/lead_view_log';
import { useConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { formatDateTime } from '@/utils/date-time';

type TProps = {
  leadView: LeadView;
};

const props = defineProps<TProps>();
const emit = defineEmits(['edit', 'afterDelete']);
const updatedAt = computed(() => {
  return formatDateTime(props.leadView.updatedAt);
});

const { confirm } = useConfirm();
const { t } = useI18n();
const { doActionWithWait } = useWait();
const { href } = useLink({
  to: { name: 'LeadList', query: { leadViewId: props.leadView.id } },
});
const api = new LeadViewApiService();
const logApi = new LeadViewLogApiService();

const handleEditClick = () => {
  emit('edit');
};

const handleDeleteClick = async () => {
  const check = await confirm({
    title: t('general.confirm.text'),
    message: t('leadView.deleteCaution'),
  });
  if (!check) {
    return;
  }
  await doActionWithWait('deleteLeadViewWait', async () => {
    await api.deleteLeadView({
      request: {
        id: props.leadView.id,
      },
    });
  });
  emit('afterDelete');
};

const handleClickLeadView = async () => {
  window.open(href.value, '_blank');

  await logApi.postLeadViewLog({
    request: {
      leadViewLogBody: {
        leadViewId: props.leadView.id,
        event: 'click',
      },
    },
  });
};
</script>

<style lang="scss" scoped>
.lead-view-card {
  @include m-flex-center;
  cursor: pointer;
  padding: $basespace-400;
  border: 1px solid $bdcolor-light;
  border-radius: $border-radius-s;
  background-color: $bgcolor-white;

  .content {
    flex: 1;
    margin: auto 0;

    .title {
      padding-bottom: $basespace-50;
    }
    .created-at {
      color: $textcolor-light;
      font-size: $fontsize-100;
    }
  }
  .user-info {
    @include m-flex-center;
    padding-left: $basespace-200;
    .empty-box {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
  .actions {
    @include m-flex-center;
    padding-left: $basespace-50;
  }
}
</style>
