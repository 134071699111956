<template>
  <BModalBody>
    <BEmptyBox
      v-if="!$wait.is('fetchLeadExportHistoriesWatch') && histories.length === 0"
      display-only
    >
      {{ i18n.t('leadExport.noHistory') }}
    </BEmptyBox>
    <template v-else>
      <div class="b-table-pagination">
        <BPagination
          v-if="paginationMeta?.totalPages > 0"
          :model-value="currentPage"
          :length="paginationMeta.totalPages"
          :total-visible="paginationMeta.limitValue"
          @update:model-value="handlePageChange"
        />
      </div>
      <div class="b-table">
        <div class="b-table-header">
          <div class="b-table-row">
            <div class="b-table-cell file-name">
              {{ i18n.t('leadExport.fileName') }}
            </div>
            <div class="b-table-cell status">
              {{ i18n.t('leadExport.status.title') }}
            </div>
            <div class="b-table-cell finished-at">
              {{ i18n.t('leadExport.finishedAt') }}
            </div>
            <div class="b-table-cell execution-frequency">
              {{ i18n.t('leadExport.executionFrequency.title') }}
            </div>
          </div>
        </div>
        <div class="b-table-tbody">
          <div
            v-for="history in histories"
            :key="history.id"
            class="b-table-row"
          >
            <div class="b-table-cell file-name">
              <a
                v-if="history.fileUrl"
                :href="history.fileUrl"
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ getFileName(history) }}
              </a>
              <span v-else>
                {{ getFileName(history) }}
              </span>
              <ElIcon
                v-if="getStatus(history) === 'success' && history.s3ObjectKey"
                width="20"
                class="download-icon"
                @click="() => download(history.s3ObjectKey)"
              >
                <Download />
              </ElIcon>
            </div>
            <div class="b-table-cell status">
              <template v-if="getStatus(history) === 'failed'">
                <BTooltip :content="i18n.t('leadExport.status.failedTooltip')">
                  <BStatus
                    status="failed"
                    :status-name="i18n.t('leadExport.status.enum.failed')"
                  />
                </BTooltip>
              </template>
              <template v-else-if="getStatus(history) === 'success'">
                <BStatus
                  status="success"
                  :status-name="i18n.t('leadExport.status.enum.success')"
                />
              </template>
              <template v-else>
                <BStatus
                  status="in-progress"
                  :status-name="i18n.t('leadExport.status.enum.inProgress')"
                />
              </template>
            </div>
            <div class="b-table-cell finished-at">
              {{ altText(formatShorterDateTime(history.finishedAt)) }}
            </div>
            <div class="b-table-cell execution-frequency">
              {{ i18n.t(`leadExport.executionFrequency.enum.${history.executionFrequency}`) }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </BModalBody>
</template>

<script lang="ts" setup>
import { Download } from '@element-plus/icons-vue';
import { onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { LeadExportTask, PaginationMeta } from '@/api/openapi';
import Api from '@/api/user';
import { LeadExportApiService } from '@/api/user/resources/lead_export';
import { useAlert } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';
import { altText } from '@/utils/alt';
import { formatShorterDateTime } from '@/utils/date-time';

type TStatus = 'success' | 'inProgress' | 'failed';
type TProps = {
  refetchHistories: boolean;
};

const props = defineProps<TProps>();
const i18n = useI18n();
const { doActionWithWait } = useWait();
const { alert } = useAlert();
const api = new LeadExportApiService();

const currentPage = ref(1);
const histories = ref<LeadExportTask[]>([]);
const paginationMeta = ref<PaginationMeta>();

const getFileName = (history: LeadExportTask): string => {
  if (history.fileName) return history.fileName;
  if (history.s3ObjectKey) return history.s3ObjectKey.split('/').at(-1);
  return '';
};

const getStatus = (history: LeadExportTask): TStatus => {
  if (history.errorData != null) return 'failed';
  if (history.finishedAt) return 'success';
  return 'inProgress';
};

onMounted(async () => {
  await fetchLeadExportHistories();
});
watch(() => props.refetchHistories, async () => {
  await fetchLeadExportHistories();
});
const fetchLeadExportHistories = async () => {
  return await doActionWithWait('fetchLeadExportHistoriesWatch', async () => {
    const { data } = await api.getLeadExportHistories({
      request: { page: currentPage.value },
    });
    histories.value = data.leadExportTasks || [];
    paginationMeta.value = data.meta;
  });
};

const handlePageChange = async (newPage: number) => {
  currentPage.value = newPage;
  await fetchLeadExportHistories();
};

const download = async (s3ObjectKey: string) => {
  try {
    const result = await Api.createExportFileStorageAccessUrl({
      body: { s3ObjectKey },
    });
    const link = document.createElement('a');
    link.href = result.data.url;
    link.click();
  } catch (err) {
    alert({
      title: i18n.t('general.error'),
      message: err.message,
    });
  }
};
</script>

<style lang="scss" scoped>
$file-name-width: calc(100% - 80px - 140px - 60px);
.b-table-cell {
  &.file-name {
    @include m-break-word-pre-wrap;
    min-width: 300px;
    max-width: $file-name-width;
    width: $file-name-width;
  }
  &.status {
    @include m-fixed-width(80px);
  }
  &.finished-at {
    @include m-fixed-width(140px);
  }
  &.execution-frequency {
    @include m-fixed-width(60px);
  }
}
.b-table-tbody {
  font-size: $fontsize-100;
  .file-name {
    display: flex;
    align-items: center;
    .download-icon {
      cursor: pointer;
      margin-left: auto;
    }
  }
}
.b-empty-box {
  padding: $basespace-200;
}
</style>
