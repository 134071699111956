import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExportDataFormat, ExportFileType, PartLeadExportSettingCommon } from '@/api/openapi';

const makeInitialModel = (): PartLeadExportSettingCommon => {
  return {
    fileType: ExportFileType.Excel,
    dataFormat: ExportDataFormat.AllActivity,
    writeTo: null,
    leadListFilterParams: {},
  };
};

const useLeadLimit = (getNumberOfLeads: () => number, getDataFormat: () => ExportDataFormat) => {
  const i18n = useI18n();
  const MAX_LEADS = 2000;

  const tooManyLeads = computed(() => {
    return getNumberOfLeads() > MAX_LEADS && getDataFormat() === ExportDataFormat.AllActivity;
  });
  const tooManyLeadsMessage = computed(() => {
    return tooManyLeads.value ? i18n.t('leadExport.tooManyLeads', { max: MAX_LEADS }) : '';
  });

  return { tooManyLeads, tooManyLeadsMessage };
};

export {
  makeInitialModel,
  useLeadLimit,
};
