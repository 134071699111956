import { inject } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';

const useModal = () => {
  const modal = inject(KEY_OF_MODAL);
  const showModal = (name: string) => {
    modal.show(name);
  };
  return { showModal };
};

export { useModal };
