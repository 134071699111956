<template>
  <BModalBody>
    <div class="form-items">
      <div class="description">
        {{ $t('leadExport.scheduledExportSettingShowDescription') }}
      </div>
      <BListItem class="block-title">
        <span>{{ $t('leadExport.exportFormatBlock') }}</span>
      </BListItem>
      <BListItem class="list-item">
        <ElDescriptions
          :column="1"
          border
        >
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.fileType.title') }}</span>
            </template>
            {{ exportFileType }}
          </ElDescriptionsItem>
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.dataFormat.title') }}</span>
            </template>
            {{ exportDataFormat }}
          </ElDescriptionsItem>
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.writeTo') }}</span>
            </template>
            {{ model.writeTo }}
          </ElDescriptionsItem>
        </ElDescriptions>
      </BListItem>
      <BListItem class="block-title">
        <span>{{ $t('leadExport.scheduledExportBlock') }}</span>
      </BListItem>
      <BListItem class="list-item">
        <ElDescriptions
          :column="1"
          border
        >
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.fileName') }}</span>
            </template>
            {{ model.fileName }}
          </ElDescriptionsItem>
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.interval.title') }}</span>
            </template>
            {{ interval }}
          </ElDescriptionsItem>
          <ElDescriptionsItem v-if="model.interval === PartLeadExportSettingScheduledIntervalEnum.Weekly">
            <template #label>
              <span>{{ $t('leadExport.daysOfWeek') }}</span>
            </template>
            <BDayOfWeekCheckButtons
              :model-value="model.daysOfWeek"
              display-only
            />
          </ElDescriptionsItem>
          <ElDescriptionsItem v-if="model.interval === PartLeadExportSettingScheduledIntervalEnum.Monthly">
            <template #label>
              <span>{{ $t('leadExport.daysOfMonth') }}</span>
            </template>
            {{ daysOfMonth }}
          </ElDescriptionsItem>
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.time') }}</span>
            </template>
            {{ model.time }}
          </ElDescriptionsItem>
        </ElDescriptions>
      </BListItem>
    </div>
  </BModalBody>
  <BModalFooter>
    <BBtn
      type="error"
      outline
      @click="handleDeleteClick"
    >
      {{ $t('leadExport.scheduledExportSettingDelete') }}
    </BBtn>
  </BModalFooter>
</template>

<script lang="ts" setup>
import { camelCase } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { PartLeadExportSettingScheduledIntervalEnum, ScheduledLeadExportSetting } from '@/api/openapi';
import { LeadExportApiService } from '@/api/user/resources/lead_export';
import { useConfirm } from '@/composable/message-dialog';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  model: ScheduledLeadExportSetting;
};

const props = defineProps<TProps>();
const emit = defineEmits(['afterDelete']);

const { doActionWithWait } = useWait();
const i18n = useI18n();
const { confirm } = useConfirm();
const api = new LeadExportApiService();

const exportFileType = computed(() => {
  return props.model.fileType ? i18n.t(`leadExport.fileType.enum.${camelCase(props.model.fileType)}`) : '';
});
const exportDataFormat = computed(() => {
  return props.model.dataFormat ? i18n.t(`leadExport.dataFormat.enum.${camelCase(props.model.dataFormat)}`) : '';
});
const interval = computed(() => {
  return props.model.interval ? i18n.t(`leadExport.interval.enum.${camelCase(props.model.interval)}`) : '';
});
const daysOfMonth = computed(() => {
  return props.model.daysOfMonth ? props.model.daysOfMonth.map((day) => `${day}日`).join(', ') : '';
});

const handleDeleteClick = async () => {
  const result = await confirm({
    message: i18n.t('general.confirm.actionWithTarget', {
      action: i18n.t('general.delete.text'),
      target: i18n.t('leadExport.scheduledExportSetting'),
    }),
  });
  if (!result) return;

  await doActionWithWait('deleteScheduledLeadExportSettingWait', async () => {
    await api.deleteScheduledLeadExportSetting();
  });
  emit('afterDelete');
};
</script>
